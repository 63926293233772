<template>
  <div class="PlantManageList-wrapper">
    <list
      ref="list"
      exportPermission="export"
      :searchUrl="searchUrl"
      :exportUrl="exportUrl"
      :searchParams.sync="searchParams"
      :headers="headers"
      :isMultiSelect="true"
      :multiExport="multiExport"
    >
      <template #headerSlot>
        <v-button text="新增植被" permission="add" @click="create"></v-button>
        <v-button text="导入" permission="import" @click="importHandle"></v-button>
      </template>
      <template #searchSlot>
        <v-input label="植被名称" v-model="searchParams.plantName"></v-input>
        <v-select2 label="所属项目" v-model="searchParams.communityId" v-bind="communityParams"></v-select2>
        <v-select2 label="所属公司" v-model="searchParams.regionId" v-bind="regionParams"></v-select2>
        <v-input label="科目" v-model="searchParams.depart"></v-input>
        <v-input label="属" v-model="searchParams.attribute"></v-input>
        <v-input label="种" v-model="searchParams.variety"></v-input>
        <v-select label="状态" v-model="searchParams.status" :options="statusOps"></v-select>
        <v-datepicker
          label="操作时间段"
          :startTime.sync="searchParams.operateStartTime"
          :endTime.sync="searchParams.operateEndTime">
        </v-datepicker>
      </template>
      <template #operateSlot="scope">
        <v-button text="编辑" type="text" permission="update" @click="edit(scope.row)"></v-button>
      </template>
      <template #batchSlot="scope">
        <v-button
          text="批量生成二维码"
          permission="qrcode"
          @click="batchQRCodeHandle(scope.selectedData)"
        ></v-button>
        <v-button
          text="批量删除"
          permission="close"
          @click="batchDeleteHandle(scope.selectedData)">
        </v-button>
      </template>
      <qrcode-collection
        :qrcode-list="qrcodeList"
        :visible.sync="qrcodeCollectionShow"
      >
        <template #qrcodeSlot="scope">
          <div>
            <div>{{scope.row.plantName}}</div>
            <div>{{`${scope.row.depart}-${scope.row.attribute}-${scope.row.variety}`}}</div>
          </div>
        </template>
      </qrcode-collection>
    </list>
  </div>
</template>

<script>
import { regionParams, communityParams } from 'common/select2Params'
import { getListURL, batchURL, exportListURL, exportQRCodeURL } from './api'
import { statusMap, statusOps } from './map'
import { createHTMLVNode } from 'common/vdom'
import { QrcodeCollection } from 'components/bussiness/index'

export default {
  name: 'PlantManageList',
  components: {
    QrcodeCollection
  },
  data () {
    return {
      searchUrl: getListURL,
      exportUrl: exportListURL,
      statusOps: statusOps(1),
      communityParams: communityParams,
      regionParams: regionParams,
      searchParams: {
        userName: '',
        status: undefined,
        orgId: '',
        fromtDate: '',
        toDate: ''
      },
      headers: [
        {
          prop: 'plantName',
          label: '绿植名称'
        },
        {
          prop: 'departContent',
          label: '科-属-种',
          formatter (row) {
            return `${row.depart}-${row.attribute}-${row.variety}`
          }
        },
        {
          prop: 'habit',
          label: '习性',
          formatter (row) {
            return row.habit || '-'
          }
        },
        {
          prop: 'plantTime',
          label: '种植时间',
          formatter (row) {
            return row.plantTime || '-'
          }
        },
        {
          prop: 'plantAddress',
          label: '种植地点',
          formatter (row) {
            return row.plantAddress || '-'
          }
        },
        {
          prop: 'showManagerContent',
          label: '负责人',
          formatter: (row, prop) => {
            let showVNode = row.managerName ? `${row.managerName}<br/>${row.managerMobile}` : '-'
            return createHTMLVNode(this, showVNode)
          }
        },
        {
          prop: 'communityName',
          label: '所属项目'
        },
        {
          prop: 'companyName',
          label: '所属公司',
          formatter (row) {
            return row.companyName || '-'
          }
        },
        {
          prop: 'statusStr',
          label: '状态',
          formatter (row) {
            return statusMap[row.status]
          }
        },
        {
          prop: 'updateTime',
          label: '操作时间'
        }
      ],
      multiExport: [{
        text: '导出绿植列表',
        url: exportListURL
      }, {
        text: '导出二维码', // 导出按钮文案
        url: exportQRCodeURL // 同exportUrl
      }],
      qrcodeCollectionShow: false,
      qrcodeList: []
    }
  },
  methods: {
    create () {
      this.$router.push({
        name: 'plantManageForm'
      })
    },
    edit (row) {
      this.$router.push({
        name: 'plantManageForm',
        query: {
          id: row.id
        }
      })
    },

    // 导入的操作
    importHandle () {
      this.$router.push({
        name: 'plantManageImport'
      })
    },

    // 批量生成二维码操作
    batchQRCodeHandle (selectedData) {
      let list = selectedData.data || []
      if (list.length) {
        list.forEach(item => {
          item.encryCode = item.encryptCode
        })
        this.qrcodeList = list
        let _this = this
        this.$nextTick(() => {
          _this.qrcodeCollectionShow = true
        }, 200)
      } else {
        this.$message('请先选择要操作的数据')
      }
    },

    // 批量删除
    async batchDeleteHandle (selectedData) {
      let status = 0
      let list = selectedData.data || []
      if (list.length) {
        let firstStatus = list[0].status
        // 取出所选list，判断里面的数据状态是否一致
        let allSame = true
        list.forEach(item => {
          if (firstStatus !== item.status) {
            allSame = false
          }
        })

        if (allSame) {
          /// 代表所选数据状态一致，进一步判断
          if (firstStatus === status) {
            this.$message.success('操作成功')
          } else {
            let isOk = await this.$confirm('确定执行该操作？', { title: '提示' })
            isOk && this.requestForDelete(selectedData, status)
          }
        } else {
          this.$message('所选数据状态不一致')
        }
      } else {
        this.$message('请先选择要操作的数据')
      }
    },

    // 批量删除的请求
    requestForDelete (selectedData, status) {
      let _this = this
      this.$axios({
        method: 'PUT',
        url: batchURL,
        headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' },
        data: this.$qs.stringify({
          ids: JSON.stringify(selectedData.ids),
          status: status
        })
      }).then(res => {
        if (res.status === 100) {
          _this.$refs.list.searchData()
          _this.$message.success('操作成功')
        }
      })
    }
  }
}
</script>
